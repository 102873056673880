<template>
  <div class="popup">
    <div class="popup__bck" @click="closePopup()"></div>
    <div class="popup__block" >
      <div class="popup__close" @click="closePopup()"><inline-svg :src="require(`@/assets/images/svg/close-ic.svg`)"></inline-svg></div>
      <div class="popup__title"><span>Contact the seller</span></div>
      <div class="popup__text">
        You can write to the seller to cancel your order immediately. The seller may reject or accept your order cancellation request.
      </div>
      <textarea class="popup__textarea" placeholder="Write the message"></textarea>
      <div class="popup__btn">
        <button class="cancel" @click="closePopup()">Cancel</button>
        <button class="btn blue" @click="closePopup()">Send</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  data(){
    return{

    }
  },
  computed:{
  },
  methods:{
    closePopup(){
      this.changeToggleConfirmBuyOrderPopup(false)
    },
    ...mapMutations({
      changeToggleConfirmBuyOrderPopup:'general/changeToggleConfirmBuyOrderPopup'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.popup{
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  &__bck{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0, 0.3);
  }
  &__close{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  &__block{
    position: absolute;
    min-height: 50px;
    max-height: 90vh;
    min-width: 250px;
    max-width: 90vw;
    background-color: $white;
    border-radius: 15px;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-end;
    overflow: auto;
    max-width: 550px;
    &.success{
      box-shadow: 0 0 6px 1px $yellow;
    }
    &.error{
      box-shadow: 0 0 6px 1px $red;
    }
    @media screen and (max-width: 991px){
      max-height: 90vh;
      max-width: 90px;
    }
  }
  &__title{
    width: 100%;
    text-align: left;
    font-size: 24px;
    font-family: 'Mont', Arial;
    text-transform: uppercase;
    font-weight: 900;
    color: $grey;
    margin-bottom: 10px;
    padding: 15px 15px 15px 35px;
    margin-right: 40px;
    span{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        left: calc(100% + 25px);
        top: -15px;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: $yellow;
      }
    }
  }
  &__text{
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
    color: $grey;
    font-size: 16px;
    font-family: 'Inter', Arial;
    font-weight: 400;
  }
  &__btn{
    .cancel{
      padding: 0 35px;
    }
  }
  &__textarea{
    width: 100%;
    resize: none;
    border: 1p solid $gray-dark;
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 45px;
    height: 130px;
    font-family: 'Inter', Arial;
    font-size: 16px;
    font-weight: 400;
    color: $grey;
    &::placeholder{
      color: $gray-dark;
    }
  }
}
</style>
